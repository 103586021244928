import React from 'react'
import {graphql, Link} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { imageUrlFor } from '../lib/image-url'
import { buildImageObj } from '../lib/helpers'


export const query = graphql`
  query vendorLandingPageTemplateQuery($id: String!) {
    vendor: sanityVendor(id: {eq: $id}) {
      id
      title
      website
      categories {
        id
        title
      }
    }
  }
`

const VendorLandingPageTemplate = props => {
  const {data, errors} = props
  const vendor = data && data.vendor
  const vendorWebsite = vendor.website
  const categories = vendor.categories && vendor.categories.map(category => category.title)
  return (
    <Layout>
      {errors && <SEO title='GraphQL Error' />}
      {vendor && <SEO title={vendor.title || 'Untitled'} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      <Container>
        
        <h1>{vendor.title}</h1>
        <p>
          <Link to="/kontakt">Ta kontakt med oss</Link> dersom du ønsker hjelp med produkter fra {vendor.title}.
          Få mer inspirasjon på <a href={vendorWebsite}>leverandørens nettsider.</a>
        </p>
        <p>Vi fører disse kategoriene fra {vendor.title}:</p>
        <ul>
          {categories && categories.map(category => (
            <li>{category}</li>
          ))}
        </ul>

      </Container>
    </Layout>
  )
}

export default VendorLandingPageTemplate
